import { computed, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '../stores/RootStore'
import { Device } from '@capacitor/device'

export class RedirectVM {
  private to: string
  @observable private isWindows: boolean = false
  private DEBUG = false

  constructor(private rootStore: RootStore) {
    makeObservable(this)
    this.computeTo()
    this.loadInfo().then(() => {
      this.checkForRedirectToAppStore()
      this.checkForRedirectToPlayStore()
      setTimeout(() => runInAction(() => (this.linksShown = true)), 1000)
    })
  }

  @observable public linksShown: boolean = false

  private async loadInfo() {
    let info = await Device.getInfo()
    runInAction(() => {
      this.isWindows = info.operatingSystem === 'windows'
    })
  }

  @computed
  public get spinnerShown() {
    if (!this.linksShown) return true
    return !this.isWindows
  }

  private computeTo() {
    const paths = window.location.pathname.split('/')
    this.to = paths[paths.length - 1]
  }

  private checkForRedirectToAppStore() {
    if (this.to !== 'store') return
    if (this.isWindows) return
    if (!this.rootStore.appStore.isIos) return
    this.goToAppStore()
  }

  private checkForRedirectToPlayStore() {
    if (this.to !== 'store') return
    if (this.isWindows) return
    if (!this.rootStore.appStore.isAndroid) return
    this.goToPlayStore()
  }

  public goToPlayStore() {
    if (this.DEBUG) {
      alert('Redirecting to Play Store')
      return
    }
    setTimeout(
      () => (window.location.href = 'https://play.google.com/store/apps/details?id=com.groceryboard.app'),
      1000
    )
  }

  public goToAppStore() {
    if (this.DEBUG) {
      alert('Redirecting to App Store')
      return
    }
    setTimeout(() => (window.location.href = 'https://apps.apple.com/us/app/grocery-board/id1471128617'), 1000)
  }
}
